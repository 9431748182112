<template>
  <div class="mec">
    <header class="title x-title">{{ dateTitle }}</header>
    <header class="title2" v-if="client == 'Cloudflare'">Workplace</header>
    <header class="title2" v-else>CAMPUSES</header>
    <v-row class="ma-3 pa-0">
      <v-col cols="12" class="ma-0 pa-0" md="6" style="display: flex; align-items: center">
        <input
          style="border-bottom: 1px solid #999999; width: 350px"
          type="text"
          placeholder="Search"
          class="search_input"
          v-model="keyWords"
          @input="search"
          aria-label="search"
        />
        <div class="search_img">
          <p
            class="mx-2"
            fab
            dark
            small
            style="cursor: pointer"
            :class="{
              campus: this.client == 'CX Campus',
              clorox: this.client == 'Clorox'
            }"
          >
          
            <img :src="imgUrl" />
          </p>
        </div>
        <!--设置按钮(给campus排序或显示/隐藏)-->
        <!--<i v-if="showSettingBtn" class="iconfont icon-hanbaobao"-->
        <!--   style="font-size: 30px;color:#9e9e9e;cursor: pointer"-->
        <!--   @click="clickSetting"></i>-->
        <!--漏斗图标，计算当前展示的campus数量-->
        <v-badge
          v-if="showSettingBtn"
          color="#a3a5a3"
          :content="filterLength"
          style="cursor: pointer"
        >
          <i
            class="iconfont icon-loudou"
            @click="clickSetting"
            style="font-size: 40px;color: #a3a5a3;position: relative;top: -8px;margin-left: 40px}"
          ></i>
        </v-badge>
      </v-col>
    </v-row>
    <!--<v-row class="ma-3 pa-0"-->
    <!--       v-if="client=='adobe'||this.client=='NatWest'||this.client=='HPE Campus'||this.client=='Clorox'||this.client=='WarnerMedia'">-->
    <!--  <div class="campuses" v-for="(item,index) in campus" style="width:100%">-->
    <!--    <campusList @go="go(item)" :data="item" v-show="item.show"-->
    <!--                @keyup.enter.native="go(item)" tabindex="0"></campusList>-->
    <!--  </div>-->
    <!--</v-row>-->
    <v-row class="ma-3 pa-0">
      <div class="campuses" v-for="(item, index) in campus" style="width: 100%">
        <campusList
          @go="go(item)"
          :data="item"
          v-show="item.show"
          @keyup.enter.native="go(item)"
          tabindex="0"
        ></campusList>
      </div>
    </v-row>
    <!--操作campus的弹窗-->
    <v-dialog v-model="showMecDialog" max-width="800">
      <v-card>
        <v-card-title style="font-size: 18px">
          <span> {{ mecGroupLabel }} </span>
          <!-- <span>Campus</span> -->
          <span>
            selector
          </span>
        </v-card-title>
        <v-card-text style="font-size: 16px">
          Use the form below to select which {{ mecGroupLabel }} you would like to see displayed in
          your list of {{ mecGroupLabel }} . &nbsp;&nbsp;You must select at least one.
          &nbsp;&nbsp;After making your selection(s) and clicking 'Submit' you may change the order
          by dragging/dropping in your {{ mecGroupLabel }} list.
        </v-card-text>
        <!-- <v-card-text style="font-size: 16px">
          Use the form below to select which campuses you would like to see displayed in your list
          of campuses. &nbsp;&nbsp;You must select at least one. &nbsp;&nbsp;After making your
          selection(s) and clicking 'Submit' you may change the order by dragging/dropping in your
          Campus list.
        </v-card-text> -->
        <!--搜索和下拉选项-->
        <div style="width: 60%; margin: auto">
          <searchBox
            ref="searchBox"
            @search="searchCampusDialog"
            @keypress.enter.native.prevent
          ></searchBox>
          <v-select
            @change="selectRegion"
            v-model="selectedRegion"
            :items="regions"
            dense
            outlined
          ></v-select>
          <!--<v-btn @click="selectAll" rounded class="x-btn" style="display:flex;margin: 5px auto">Select All</v-btn>-->
          <v-checkbox v-model="choseAllCampus" label="All" @change="toggleAllCampus"></v-checkbox>
        </div>
        <!--campus列表-->
        <div
          class="checkboxes"
          style="
            width: 60%;
            padding: 0 10px;
            margin: auto;
            height: 400px;
            overflow-y: scroll;
          "
        >
          <vuedraggable class="wrapper" v-model="campusDialog">
            <transition-group>
              <p v-for="(item, index) in campusDialog" :key="'campus' + index">
                <v-checkbox
                  v-show="item.show"
                  v-model="item.chosen"
                  :label="item.name"
                  @change="choseCampus"
                ></v-checkbox>
              </p>
            </transition-group>
          </vuedraggable>
        </div>

        <v-card-actions style="display: flex; justify-content: center">
          <!--<v-spacer></v-spacer>-->
          <v-btn color="green" text @click="cancelDialog"> Cancel </v-btn>
          <v-btn color="green" text @click="ok"> Submit </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--用户第一次进来的时候,点开设置按钮提示的内容-->
    <v-dialog v-model="dialogFirstInTip" persistent max-width="500">
      <v-card>
        <v-card-title class="text-h5"> Confirmation </v-card-title>
        <v-card-text style="font-size: 16px">
          <span>Change the order by dragging/dropping in your Campus list.&nbsp;</span>
          <span
            >Select which campuses you would like to see displayed in your Campus List.&nbsp;</span
          >
          <span style="font-weight: bold"> You must select at least one.&nbsp;</span>
          <span>Click ‘Submit’ after making your selections.</span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="clickMecDialogConfirm">
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import campusList from "@/views/dashboard/componentsHa/campusList";
import searchBox from "@/views/dashboard/componentsHa/searchBox";
import { mapMutations } from "vuex";
import vuedraggable from "vuedraggable";
import showSelector from "@/util/selector";

export default {
  name: "mec",
  components: {
    campusList,
    searchBox,
    vuedraggable
  },
  watch: {
    // showMecDialog(newVal, oldVal) {
    //   if (newVal) {
    //     this.choseCampus();
    //   }
    //   let condition = JSON.parse(localStorage.getItem("mecTipDialog"));
    //   if (!condition) {
    //     setTimeout(() => {
    //       this.dialogFirstInTip = true;
    //     }, 500)
    //   }
    // },
    showMecDialog(newVal, oldVal) {
      if (newVal) {
        this.choseCampus();
      }
      this.funHasLoginBefore();
    }
    // campus(newVal, oldVal) {
    //   this.filterLength = newVal.length;
    // }
  },
  data() {
    return {
      mecGroupLabel: "Campus",
      filterLength: 0,
      choseAllCampus: false,
      searchTimeout: null,
      campus: [], //所有的campus(初始数据)
      // campusAll: [],//所有的campus(备份)
      campusFirstIn: [], //初始化页面上展示的campus
      campusDialog: [], //弹窗里面的campus
      campusDialogOrigin: [], //弹窗里面的campus
      keyWords: "",
      dateTitle: "",
      imgUrl: "",
      client: "",
      showMecDialog: false,
      regions: ["All Regions"], //弹窗中显示的campus
      selectedRegion: "All Regions",
      timeOutSearchInDialog: null,
      showCampus: false,
      showSettingBtn: false, //搜索旁边的设置按钮显示
      dialogFirstInTip: false //用户第一次进来的时候,点开设置按钮提示的内容
    };
  },

  methods: {
    ...mapMutations("campusName", ["setCampusName"]),
    // campus全部选中
    // selectAll(){
    //   this.campusDialog.map(item=>{
    //     this.$set(item,"chosen",true);
    //   })
    // },

    // 判断当前用户是否登录过
    funHasLoginBefore() {
      let hasLoginBefore = false;
      let id = localStorage.getItem("userId");
      let md5Id = this.$md5(id);
      let mecTipDialog = localStorage.getItem("mecTipDialog");
      if (mecTipDialog) {
        let mecTicDialogArr = JSON.parse(localStorage.getItem("mecTipDialog"));
        hasLoginBefore = mecTicDialogArr.indexOf(md5Id) > -1 ? true : false;
      }
      if (!hasLoginBefore) {
        setTimeout(() => {
          this.showMecDialog = true;
          this.dialogFirstInTip = true;
        }, 500);
      }
    },
    //第一次点击设置按钮打开的弹窗，点击OK的回调
    clickMecDialogConfirm() {
      this.dialogFirstInTip = false;
      let id = localStorage.getItem("userId");
      let md5Id = this.$md5(id);
      let mecTipDialog = localStorage.getItem("mecTipDialog");
      console.log("id:", id);
      console.log("md5Id:", md5Id);
      console.log("mecTipDialog:", mecTipDialog);
      if (mecTipDialog) {
        let mecTicDialogArr = JSON.parse(localStorage.getItem("mecTipDialog"));
        mecTicDialogArr.push(md5Id);
        let jsonMecTicDialogArr = JSON.stringify(mecTicDialogArr);
        localStorage.setItem("mecTipDialog", jsonMecTicDialogArr);
        console.log("mecTicDialogArr:", mecTicDialogArr);
      } else {
        let arr = [];
        arr.push(md5Id);
        localStorage.setItem("mecTipDialog", JSON.stringify(arr));
        console.log("arr:", arr);
      }
    },
    toggleAllCampus() {
      if (this.choseAllCampus) {
        this.campusDialog.map(item => {
          if (item.show) {
            this.$set(item, "chosen", true);
          }
        });
      } else {
        this.campusDialog.map(item => {
          if (item.show) {
            this.$set(item, "chosen", false);
          }
        });
      }
    },
    selector() {
      if (showSelector) {
        this.bus.$emit("loading", true);
        let that = this;
        this.$axios({
          method: "GET",
          url: "/m/mec/campus/selector"
        })
          .then(res => {
            this.bus.$emit("loading", false);
            let resData = res.data.data;
            let arr = [];
            // 初始化界面上的campusList
            resData.map(item => {
              let length = that.campus.length;
              for (let i = 0; i < length; i++) {
                let jtem = that.campus[i];
                if (item == jtem.id) {
                  jtem.show = true;
                  arr.push(jtem);
                  break;
                }
              }
            });
            this.campus = JSON.parse(JSON.stringify(arr));
            // url有jumpToBooking和meetingIdMap的直接就跳转到Booking页面了
            console.log("localStorage.jumpToBooking", localStorage.jumpToBooking);
            console.log("localStorage.meetingIdMap1111", localStorage.meetingIdMap);
            if (localStorage.meetingIdMap != "") {
              let length = this.campus.length;
              for (let i = 0; i < length; i++) {
                let item = this.campus[i];
                if (item.id == localStorage.meetingIdMap) {
                  if (localStorage.jumpToBooking == "true") {
                    this.go(item, true);
                  } else {
                    this.go(item);
                  }
                  // break
                  return;
                }
              }
            }
            this.campusFirstIn = JSON.parse(JSON.stringify(this.campus)); //记录当前的campus用来清空搜索时迅速返回初始数据
            this.showCampus = true;
            // 初始化弹窗里面的campusList
            let arr2 = [];
            let arr3 = [];
            let arr4 = [];
            resData.map(item => {
              let length = that.campusDialog.length;
              for (let i = 0; i < length; i++) {
                let jtem = that.campusDialog[i];
                if (item == jtem.id) {
                  jtem.chosen = true;
                  jtem.show = true;
                  arr2.push(jtem);
                  break;
                }
              }
            });
            let noChosenList = [];
            noChosenList = JSON.parse(JSON.stringify(this.campusDialog));
            arr3 = noChosenList.filter(item => !arr2.some(ele => ele.id === item.id)); //把没有选中的meeting拿出来然后再拼接到下面
            arr4 = arr2.concat(arr3);
            // 如果全部选中的话,All也勾选上
            if (arr3.length == 0) {
              this.choseAllCampus = true;
            } else {
              this.choseAllCampus = false;
            }
            this.campusDialog = JSON.parse(JSON.stringify(arr4));
            this.campusDialogOrigin = JSON.parse(JSON.stringify(that.campusDialog));
            this.showSettingBtn = true; //显示设置按钮(打开弹窗)
            console.log(
              "====showSettingBtnshowSettingBtnshowSettingBtn =====",
              this.showSettingBtn
            );
            // 用户首次进来的时候提示他可以拖拉campus list
            this.funHasLoginBefore();

            // let mecTipDialog = JSON.parse(localStorage.getItem("mecTipDialog"));
            // let id = localStorage.getItem("userId");
            // let condition = id === mecTipDialog?true:false;
            // console.log("====condition =====", condition)
            // if (!condition) {
            //   this.showMecDialog = true;
            //   // this.$confirm({title: "Confirmation", message: 'Change the order by dragging/dropping in your Campus list.', show: true}).then(() => {
            //   //   //用户点击确认后执行
            //   //   sessionStorage.setItem("mecTip",true)
            //   // })
            // }
            console.log("==== showSettingBtn=====", this.showSettingBtn);
          })
          .catch(err => {
            this.bus.$emit("loading", false);
            console.log(err);
          });
      } else {
        console.log("localStorage.meetingIdMap22222", localStorage.meetingIdMap);
        this.bus.$emit("loading", false);
        this.campusFirstIn = JSON.parse(JSON.stringify(this.campus));
        // 判断如果localStorage里面有meetingId则自动跳转到Space页面(cms跳转到map的功能)
        // console.log("====localStorage.meetingIdMap =====",localStorage.meetingIdMap)
        if (localStorage.meetingIdMap != "") {
          let length = this.campus.length;
          for (let i = 0; i < length; i++) {
            let item = this.campus[i];
            if (item.id == localStorage.meetingIdMap) {
              console.log("==== jumpToBooking222=====", localStorage.jumpToBooking);
              if (localStorage.jumpToBooking == "true") {
                this.go(item, true);
              } else {
                this.go(item);
              }
              break;
            }
          }
        }
      }
    },
    setSelector() {
      console.log("==== this.campusDialog=====", this.campusDialog);
      let arr = [];
      this.campusDialog.map((item, index) => {
        if (item.chosen == true) {
          arr.push(item.id);
        }
      });
      console.log("====arr =====", arr);
      if (arr.length == 0) {
        let msg = "You must select at least one campus to be displayed.";
        this.$confirm({ title: "Confirmation", message: msg, show: true });
        return;
      } else {
        this.bus.$emit("loading", true);
      }
      this.$axios({
        method: "post",
        url: "/m/mec/campus/selector",
        data: {
          meetingIds: arr
        }
      })
        .then(res => {
          this.bus.$emit("loading", false);
          this.$message.success("Success");
          this.campusDialogOrigin = JSON.parse(JSON.stringify(this.campusDialog));
          this.showMecDialog = false;
          // this.campus = JSON.parse(JSON.stringify(this.campusDialog));

          let arr = [];
          this.campusDialog.map(item => {
            if (item.chosen) {
              arr.push(item);
            }
          });
          console.log("====arrr =====", arr);
          this.campus = JSON.parse(JSON.stringify(arr));
          this.campus.map(item => {
            this.$set(item, "show", true);
          });
          console.log("==== campus=====", this.campus);
          this.campusFirstIn = JSON.parse(JSON.stringify(arr));
          // this.campus.map(item => {
          //   let length = arr.length;
          //   item.show = false;
          //   for (let i = 0; i < length; i++) {
          //     let jtem = arr[i];
          //     if (item.id == jtem) {
          //       item.show = true;
          //       break
          //     }
          //   }
          // })
          // this.getCampus()
        })
        .catch(err => {
          this.bus.$emit("loading", false);
          console.log(err);
        });
    },
    // getClient() {
    //   this.getClientData().then(res => {
    //     this.client = res.data.Client;
    //     if (res.data.Client == 'Clorox') {
    //       this.imgUrl = require('@/assets/images/desks/desks_search_green.png')
    //     } else {
    //       this.imgUrl = require('@/assets/images/desks/desks_search.png')
    //     }
    //   }).catch(err => {
    //     console.log(err);
    //   });
    // },
    getClient() {
      this.client = localStorage.getItem("client");
      if (this.client == "Clorox") {
        this.imgUrl = require("@/assets/images/desks/desks_search_green.png");
      } else {
        this.imgUrl = require("@/assets/images/desks/desks_search.png");
      }
    },
    // 获取campus
    // getCampus() {
    //   let url = '/m/meetings/';
    //   axios({
    //     method: 'GET',
    //     url: url,　　　　　　　　//利用了字符串模板来携带id
    //     params: {
    //       meetingId: localStorage.meetingIdMap ? localStorage.meetingIdMap : '',
    //       // token:window.localStorage.getItem('token')
    //     },
    //   }).then(res => {
    //     this.filterLength = res.data.length;
    //     let arr = [];
    //     res.data.map(item => {
    //       // console.log("====item =====",item.name);
    //       // console.log("====showDeskButton =====",item.showDeskButton);
    //       // console.log("====showRoomButton =====",item.showRoomButton);
    //       let showDeskOrRoom = true;
    //       if (this.client == 'adobe' || this.client == 'NatWest' || this.client == 'HPE Campus' || this.client == 'Clorox'||this.client=='WarnerMedia') {
    //         if (item.showDeskButton) {
    //           showDeskOrRoom = true;
    //         } else {
    //           if (item.showRoomButton) {
    //             showDeskOrRoom = true;
    //           } else {
    //             showDeskOrRoom = false;
    //           }
    //         }
    //       }
    //       if (item.type == 'Campus' && showDeskOrRoom) {
    //         arr.push(item);
    //       }
    //     })
    //     this.campus = arr;//展示的列表
    //     this.campus.map((item, index) => {
    //       if (this.client == 'adobe' || this.client == 'NatWest' || this.client == 'HPE Campus' || this.client == 'Clorox'||this.client=='WarnerMedia') {
    //         this.$set(this.campus[index], "show", false);
    //       } else {
    //         this.$set(this.campus[index], "show", true);
    //       }
    //     })
    //     // this.campusAll = JSON.parse(JSON.stringify(this.campus));//用于清空搜索的时候显示回所有列表
    //     // this.campusAll.map((item, index) => {
    //     //   this.$set(this.campusAll[index], "show", false);
    //     // })
    //     //dialog里面的campus
    //     this.campusDialog = JSON.parse(JSON.stringify(this.campus));
    //     this.campusDialog.map((item, index) => {
    //       this.$set(this.campusDialog[index], "show", true);
    //     })
    //     this.campusDialogOrigin = JSON.parse(JSON.stringify(this.campusDialog));
    //     this.selector();
    //   }).catch(err => {
    //     console.log(err);
    //   })
    // },

    // 新接口 同/m/meetings
    getCampus() {
      this.bus.$emit("loading", true);
      let url = "/m/mec";
      axios({
        method: "GET",
        url: url //利用了字符串模板来携带id
      })
        .then(res => {
          this.bus.$emit("loading", false);
          this.filterLength = res.data.length;
          let arr = [];
          let arrRegion = [];
          let dataCampusCheckIn = [];
          res.data.map(item => {
            // console.log('item:',item);
            if (item.type == "Campus") {
              // console.log('sun123',item.enableCampusCheckIn == true,item.enableCampusCheckIn,item,item.name);
              arr.push(item);
              console.log(item.enableCampusCheckIn, "========0000");
              dataCampusCheckIn.push(item.enableCampusCheckIn);
              // if(item.enableCampusCheckIn == true){
              //   console.log('yes');
              //   this.$store.state.globalShowCheckIn = true;
              //   localStorage.globalShowCheckIn = 'true';
              // }
            }

            if (item.venue.campusRegion && item.venue.campusRegion.name) {
              arrRegion.push(item.venue.campusRegion.name);
            }
          });
          if (dataCampusCheckIn.indexOf(true) > -1) {
            localStorage.globalShowCheckIn = "true";
          } else {
            localStorage.globalShowCheckIn = "false";
          }
          console.log(localStorage.globalShowCheckIn, "opopopp");
          // if(localStorage.globalShowCheckIn != 'true'){
          //   console.log(localStorage.globalShowCheckIn,'opopopp');
          //   localStorage.globalShowCheckIn = 'false';
          // }else{
          //   console.log(localStorage.globalShowCheckIn,'opopopp');
          // }
          this.regions = arrRegion;
          this.regions.unshift("All Regions");
          this.campus = arr; //展示的列表
          // console.log("====this.campus =====", this.campus)
          this.campus.map((item, index) => {
            if (showSelector) {
              this.$set(this.campus[index], "show", false);
            } else {
              this.$set(this.campus[index], "show", true);
            }
          });
          //dialog里面的campus
          this.campusDialog = JSON.parse(JSON.stringify(this.campus));
          this.campusDialog.map((item, index) => {
            this.$set(this.campusDialog[index], "show", true);
          });
          this.campusDialogOrigin = JSON.parse(JSON.stringify(this.campusDialog));
          this.selector();
        })
        .catch(err => {
          this.bus.$emit("loading", false);
          let msg = err.response.data.error;
          this.$confirm({
            title: "Confirmation",
            message: msg,
            show: true
          });
          console.log(err);
        });
    },
    // 将字符串中的大写改为小写
    strChange(arg) {
      var str = arg.split("");
      for (var i = 0; i < str.length; i++) {
        if (str[i].charCodeAt() >= 65 && str[i].charCodeAt() <= 90) {
          str[i] = str[i].toLowerCase();
        }
      }
      return str.join("");
    },
    // 模糊搜索
    fuzzyMatch(str, key) {
      let index = -1,
        flag = false;
      for (var i = 0, arr = key.split(""); i < arr.length; i++) {
        //有一个关键字都没匹配到，则没有匹配到数据
        if (str.indexOf(arr[i]) < 0) {
          break;
        } else {
          let match = str.matchAll(arr[i]);
          let next = match.next();
          while (!next.done) {
            if (next.value.index > index) {
              index = next.value.index;
              if (i === arr.length - 1) {
                flag = true;
              }
              break;
            }
            next = match.next();
          }
        }
      }
      return flag;
    },
    // 查询（旧版，更加模糊，匹配更多，暂时弃用）
    // search() {
    //   if (this.searchTimeout) {
    //     clearTimeout(this.searchTimeout);
    //     this.searchTimeout = null;
    //   }
    //   this.searchTimeout = setTimeout(() => {
    //     console.log(1)
    //     if (!this.keyWords) {
    //       this.campus = this.campusAll;
    //       return
    //     }
    //     let arr = [];
    //     let searchCampus = [];
    //     let campusAllCopy = JSON.parse(JSON.stringify(this.campusAll));
    //     campusAllCopy.map(item => {
    //       let itemNameLower = this.strChange(item.name);
    //       arr.push(itemNameLower);
    //     })
    //     arr.map((item, index) => {
    //       let res = this.fuzzyMatch(item, this.strChange(this.keyWords));
    //       console.log(res);
    //       if (res) {
    //         searchCampus.push(this.campusAll[index]);
    //       }
    //     })
    //     this.campus = searchCampus;
    //     let id = localStorage.getItem("googleAnaId");
    //     let campusName = localStorage.getItem("campusName");
    //     if (window.gtag) {
    //       window.gtag('event', 'mec_search', {
    //         event_category: 'mec_search_action',
    //         event_label: 'mec_search',
    //         send_to: id,
    //         value: this.keyWords,
    //         dimension1: campusName
    //         // send_to: this.GA_TRACKING_ID
    //       })
    //     }
    //
    //   }, 500)
    // },
    searchOld() {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
        this.searchTimeout = null;
      }
      this.searchTimeout = setTimeout(() => {
        console.log(1);
        if (!this.keyWords) {
          this.campus = this.campusAll;
          return;
        }
        let arr = [];
        let searchCampus = [];
        let campusAllCopy = JSON.parse(JSON.stringify(this.campusAll));
        campusAllCopy.map(item => {
          let itemNameLower = this.strChange(item.name);
          arr.push(itemNameLower);
        });
        arr.map((item, index) => {
          let res = this.fuzzyMatch(item, this.strChange(this.keyWords));
          console.log(res);
          if (res) {
            searchCampus.push(this.campusAll[index]);
          }
        });
        this.campus = searchCampus;
        let id = localStorage.getItem("googleAnaId");
        let campusName = localStorage.getItem("campusName");
        if (window.gtag) {
          window.gtag("event", "mec_search", {
            event_category: "mec_search_action",
            event_label: "mec_search",
            send_to: id,
            value: this.keyWords,
            dimension1: campusName
            // send_to: this.GA_TRACKING_ID
          });
        }
      }, 500);
    },
    // 界面上方的search功能
    search() {
      let that = this;
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
        this.searchTimeout = null;
      }
      this.searchTimeout = setTimeout(() => {
        if (!this.keyWords) {
          this.campus = JSON.parse(JSON.stringify(this.campusFirstIn));
          // this.campus.map((item,index)=>{
          //   this.$set(this.campus[index],"show",true);
          // })
          return;
        }
        this.campus.map((item, index) => {
          let smallKey = that.keyWords.toLowerCase();
          let small = item.name.toLowerCase();
          if (small.indexOf(smallKey) > -1) {
            that.$set(that.campus[index], "show", true);
          } else {
            that.$set(that.campus[index], "show", false);
          }
        });
        let id = localStorage.getItem("googleAnaId");
        let campusName = localStorage.getItem("campusName");
        if (window.gtag) {
          window.gtag("event", "mec_search", {
            event_category: "mec_search_action",
            event_label: "mec_search",
            send_to: id,
            value: this.keyWords,
            dimension1: campusName
            // send_to: this.GA_TRACKING_ID
          });
        }
      }, 500);
    },
    // dialog里面的campus search
    searchCampusDialog(data) {
      if (data == "") {
        console.log("==== 空=====");
        console.log("selectedRegion", this.selectedRegion);
        if (this.selectedRegion == "All Regions") {
          this.campusDialog.map((item, index) => {
            this.$set(this.campusDialog[index], "show", true);
          });
        } else {
          this.campusDialog.map((item, index) => {
            if (item.venue.campusRegion.name == this.selectedRegion) {
              console.log("campusRegion", item.venue.campusRegion.name);
              this.$set(this.campusDialog[index], "show", true);
            }
          });
        }

        console.log("==== campusDialog=====", this.campusDialog);
        if (this.timeOutSearchInDialog) {
          clearTimeout(this.timeOutSearchInDialog);
          this.timeOutSearchInDialog = null;
        }
        return;
      }
      console.log("==== 2222222222222222222=====");
      let that = this;
      if (this.timeOutSearchInDialog) {
        clearTimeout(this.timeOutSearchInDialog);
        this.timeOutSearchInDialog = null;
      }
      this.timeOutSearchInDialog = setTimeout(() => {
        console.log("==== campusDialog222222222=====", this.campusDialog);
        this.campusDialog.map((item, index) => {
          let smallKey = data.toLowerCase();
          let small = item.name.toLowerCase();
          console.log("smallKey", smallKey);
          console.log("small", small);
          if (item.show && small.indexOf(smallKey) > -1) {
            console.log("找到了", small);
            that.$set(that.campusDialog[index], "show", true);
          } else {
            console.log("没找到", small);
            that.$set(that.campusDialog[index], "show", false);
          }
        });
      }, 1000);
    },
    // 选择campus
    // go(data, jumpToBooking = false) {
    //   localStorage.meetingIdMap = data.id;
    //   localStorage.userLocationSharing = data.userLocationSharing;
    //   localStorage.setItem("campusName", data.name);
    //   localStorage.setItem("maxDensityPercentage", data.maxDensityPercentage);
    //   localStorage.setItem("workingHourStartTime", data.workingHourStartTime);
    //   localStorage.setItem("workingHourEndTime", data.workingHourEndTime);
    //   localStorage.setItem("showDeskButton", data.showDeskButton);
    //   localStorage.setItem("showRoomButton", data.showRoomButton);
    //   console.log("meetingIdMap:========", localStorage.meetingIdMap);
    //   this.setCampusName(data.name);
    //   if (jumpToBooking) {
    //     this.$router.push({
    //       path: "/booking",
    //       // query: {
    //       //   meetingId: localStorage.meetingIdMap,
    //       // }
    //     }).catch(err => {
    //       console.log(err);
    //     });
    //   } else {
    //     this.$router.push({
    //       path: "/spaces",
    //       // query: {
    //       //   meetingId: localStorage.meetingIdMap,
    //       // }
    //     }).catch(err => {
    //       console.log(err);
    //     });
    //   }
    //   let id = localStorage.getItem("googleAnaId");
    //   if (window.gtag) {
    //     window.gtag('event', 'mec_campus_click', {
    //       event_category: 'go_btn_click',
    //       event_label: 'go',
    //       send_to: id,
    //       value: data.name
    //       // send_to: this.GA_TRACKING_ID
    //     })
    //   }
    //
    //   // localStorage.setItem("meetingId",data.id);
    // },
    go(data, jumpToBooking = false) {
      this.bus.$emit("loading", true);
      axios
        .request({
          method: "GET",
          url: "/m/mec/" + data.id
        })
        .then(res => {
          this.bus.$emit("loading", false);
          console.log("res!!!!!!!!!!:", res);
          const d = res.data;
          this.$store.state.globalShowCheckIn = d.enableCampusCheckIn;
          localStorage.setItem("globalShowCheckIn", d.enableCampusCheckIn);
          localStorage.meetingIdMap = d.id;
          localStorage.userLocationSharing = d.userLocationSharing;
          localStorage.enableCampusCheckIn = d.enableCampusCheckIn;
          localStorage.setItem("campusName", d.name);
          localStorage.setItem("maxDensityPercentage", d.maxDensityPercentage);
          localStorage.setItem("workingHourStartTime", d.workingHourStartTime);
          localStorage.setItem("workingHourEndTime", d.workingHourEndTime);
          localStorage.setItem("showDeskButton", d.showDeskButton);
          localStorage.setItem("showRoomButton", d.showRoomButton);
          localStorage.setItem("timezone", d.timezone);
          if (d.advanceBookingLimit) {
            localStorage.setItem("advanceBookingLimit", d.advanceBookingLimit);
          }
          if (d.advanceRoomBookingLimit) {
            localStorage.setItem("advanceRoomBookingLimit", d.advanceRoomBookingLimit);
          }
          console.log(
            "meetingIdMap:========",
            localStorage.meetingIdMap,
            localStorage.globalShowCheckIn
          );
          this.setCampusName(data.name);
          if (jumpToBooking) {
            this.$router
              .push({
                path: "/booking"
                // query: {
                //   meetingId: localStorage.meetingIdMap,
                // }
              })
              .catch(err => {
                console.log(err);
              });
          } else {
            this.$router
              .push({
                path: "/spaces"
                // query: {
                //   meetingId: localStorage.meetingIdMap,
                // }
              })
              .catch(err => {
                console.log(err);
              });
          }
          let id = localStorage.getItem("googleAnaId");
          if (window.gtag) {
            window.gtag("event", "mec_campus_click", {
              event_category: "go_btn_click",
              event_label: "go",
              send_to: id,
              value: data.name
              // send_to: this.GA_TRACKING_ID
            });
          }
        })
        .catch(err => {
          this.bus.$emit("loading", false);
          console.log(err);
        });
      // localStorage.setItem("meetingId",data.id);
    },
    // 打开mec设置弹窗
    clickSetting() {
      this.showMecDialog = true;
    },
    // mec设置弹窗确认提交
    ok() {
      this.setSelector();
    },
    // mec设置弹窗选择地区
    selectRegion() {
      console.log("==== selectedRegion=====", this.selectedRegion);
      if (this.selectedRegion == "All Regions") {
        this.campusDialog.map(item => {
          console.log("====campusDialog item =====", item.venue.campusRegion.name);
          this.$set(item, "show", true);
        });
        let isChoseAll = true;
        let length = this.campusDialog.length;
        for (let i = 0; i < length; i++) {
          let item = this.campusDialog[i];
          console.log("item", item);
          if (item.show && !item.chosen) {
            console.log("找到了", item);
            isChoseAll = false;
            break;
          }
        }
        console.log("isChoseAll", isChoseAll);
        if (isChoseAll) {
          this.choseAllCampus = true;
        } else {
          this.choseAllCampus = false;
        }
        return;
      } else {
        console.log("keyword", this.$refs.searchBox.name);
        let searchKeyWord = this.$refs.searchBox.name;
        let searchKeyWordSmall = searchKeyWord.toLowerCase();
        if (searchKeyWord) {
          this.campusDialog.map(item => {
            console.log("====name !!!!=====", item.venue.campusRegion.name);
            let smallItem = item.name.toLowerCase();
            let matchKeyWord = false;
            if (smallItem.indexOf(searchKeyWordSmall) > -1) {
              matchKeyWord = true;
            }
            if (matchKeyWord && item.venue.campusRegion.name == this.selectedRegion) {
              this.$set(item, "show", true);
            } else {
              this.$set(item, "show", false);
            }
          });
        } else {
          this.campusDialog.map(item => {
            console.log("====name !!!!=====", item.venue.campusRegion.name);
            if (item.venue.campusRegion.name == this.selectedRegion) {
              this.$set(item, "show", true);
            } else {
              this.$set(item, "show", false);
            }
          });
        }

        let isChoseAll = true;
        let length = this.campusDialog.length;
        for (let i = 0; i < length; i++) {
          let item = this.campusDialog[i];
          if (item.show && !item.chosen) {
            console.log("找到了", item);
            isChoseAll = false;
            break;
          }
        }
        console.log("isChoseAll", isChoseAll);
        if (isChoseAll) {
          this.choseAllCampus = true;
        } else {
          this.choseAllCampus = false;
        }
      }
    },
    // mec设置弹窗勾选campus
    choseCampus() {
      console.log("==== choseCampus=====");
      console.log("==== campusDialog=====", this.campusDialog);
      let allCheck = true;
      let length = this.campusDialog.length;
      for (let i = 0; i < length; i++) {
        let item = this.campusDialog[i];
        if (item.chosen == false || item.chosen == undefined) {
          allCheck = false;
          break;
        }
      }
      this.choseAllCampus = allCheck;
    },
    // mec设置弹窗点击cancel按钮
    cancelDialog() {
      this.showMecDialog = false;
      console.log("====campusDialog=====", this.campusDialog);
      console.log("==== campusDialogOrigin=====", this.campusDialogOrigin);
      this.campusDialog = JSON.parse(JSON.stringify(this.campusDialogOrigin));
    },
    getMecGroupLabel() {
      let config = JSON.parse(localStorage.getItem("config"));
      if (config.campus.mecGroupLabel) {
        this.mecGroupLabel = config.campus.mecGroupLabel;
      }
    }
  },
  created() {
    this.getClient();
    this.getCampus();
    this.setCampusName("At a Glance");
    this.dateTitle = moment().format("dddd MMMM DD , YYYY");
    this.getMecGroupLabel();
  }
};
</script>

<style lang="scss" scoped>
.mec {
  .title {
    //background: $main-blue;
    color: #ffffff;
    height: 40px;
    line-height: 40px;
    text-align: center;
  }

  .title2 {
    text-align: left;
    color: #000;
    height: 50px;
    line-height: 40px;
    font-size: 1.5rem;
    padding: 1rem;
  }

  .campuses:nth-child(odd) {
    background: #f3f3f3;
  }

  .campuses:nth-child(even) {
    background: #fff;
  }

  .clorox {
    background: #008751;
  }

  .campus {
    background: #ee2c54;
  }
}
</style>
<style lang="scss">
.mec {
  .v-badge__badge {
    line-height: 18px;
  }
}
</style>
